<template>
	<div class="case_detail">
		<div class="banner">
			<headers></headers>
			<img src="../../assets/image/acrebanner.png" alt="">
		</div>
		<div class="breadcrumb box">
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			  <el-breadcrumb-item :to="{ path: 'case_index' }">成功案例</el-breadcrumb-item>
			  <el-breadcrumb-item>案例详情</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="body">
			<div class="box">
				<div class="left">
					<div class="top">
						<p class="tit"> 幼儿园实景教学系统</p>
						<p class="time">发布时间：2019-08-09</p>
					</div>
					<div class="bot">
						<img src="../../assets/image/acrebanner.png" alt="">
						<p>
							文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍
						</p>
					</div>
				</div>
				<div class="right">
					<div class="title">
						热门推荐
					</div>
					<div class="recommend">
						<div class="recommend_list">
							<div class="img">
								<img src="../../assets/image/erg5.png" alt="">
							</div>
							<p>2021年第一项目</p>
						</div>
						<div class="recommend_list">
							<div class="img">
								<img src="../../assets/image/erg5.png" alt="">
							</div>
							<p>2021年第一项目</p>
						</div>
						<div class="recommend_list">
							<div class="img">
								<img src="../../assets/image/erg5.png" alt="">
							</div>
							<p>2021年第一项目</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Foot></Foot>
	</div>
</template>

<script>
	import headers from '../../components/header.vue'
	import Foot from '../../components/Foot.vue'
	export default {
		components: {
			headers,
			Foot
		},
		data() {
			return {}
		},
		mounted() {

		},
		methods: {

		}
	}
</script>

<style scoped lang="scss">
	@import "@/assets/less/public_details.scss";
</style>
